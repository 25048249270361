import EditIcon from '@mui/icons-material/Edit'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from 'src/hooks'
import Loader from 'src/elements/common/Loader'
import { getFlatEditPage } from 'src/utils'
import useActions from '../../store/useActions'
import { Add, PlaylistRemove } from '@mui/icons-material'
import { getCityText, getDistrictText, getStreetText } from '../../../../utils/address'
import { cities } from '../../components/Form/constant'
import { getFormattedDate3 } from '../../../../utils/date'

const FlatList = () => {
  const navigate = useNavigate()
  const isLoading = useTypedSelector(state => state.pages.flats.isLoading)
  const flatList = useTypedSelector(state => state.pages.flats.flatList)
  const { getFlatList, removeFlatToArchive } = useActions()
  const [flatToRemove, setFlatToRemove] = useState()
  const [cityFilter, setCityFilter] = useState(null)
  const [publicIdFilter, setPublicIdFilter] = useState('')
  const [hostFilter, setHostFilter] = useState(false)

  useEffect(() => {
    getFlatList({ city: cityFilter, publicId: publicIdFilter, crmSource: hostFilter ? 'host' : undefined })
  }, [cityFilter, publicIdFilter, hostFilter])

  const navigateToFlatCreation = () => {
    navigate('/flats/create')
  }

  const handleEditFlat = (id) => {
    navigate(getFlatEditPage(id))
  }

  const openRemoveDialog = (flat) => {
    setFlatToRemove(flat)
  }

  const handleRemoveFlatToArchive = () => {
    removeFlatToArchive({ flat: flatToRemove })
    setFlatToRemove(null)
  }

  const handleAddOwnerClick = (link) => {
    window.open(link, '_blank')
  }

  const handleHostFilterChange = (e) => {
    setHostFilter(e.target.checked)
  }

  return (
    <PageWrapper>
      <Loader isLoading={isLoading}/>
      <Button onClick={navigateToFlatCreation} variant="contained" sx={{ marginBottom: '20px' }}>
        Додати нове оголошення
      </Button>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', alignItems: 'center', marginBottom: '20px' }}>
        <Autocomplete
          disablePortal
          options={cities}
          value={cities.find(city => city.value === cityFilter)?.label || ''}
          onChange={(e, value) => {
            // @ts-ignore
            setCityFilter(value?.value)
          }}
          renderInput={(params) => <TextField {...params} label="Місто"/>}
          sx={{ width: '200px' }}/>
        <TextField
          label="Номер"
          name="publicId"
          sx={{ width: '200px' }}
          variant="outlined"
          value={publicIdFilter}
          onChange={(e) => {
            // @ts-ignore
            setPublicIdFilter(e.target.value)
          }}/>
        <FormControlLabel control={<Checkbox checked={hostFilter} onChange={handleHostFilterChange}/>} label="Хости"/>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Фото</TableCell>
              <TableCell align="left">Повне Ім'я</TableCell>
              <TableCell align="left">Місто</TableCell>
              <TableCell align="left">Район</TableCell>
              <TableCell align="left">Адреса</TableCell>
              {/*<TableCell align="left">ЖК</TableCell>*/}
              <TableCell align="left">Створено</TableCell>
              <TableCell align="left">Дата Створення</TableCell>
              <TableCell align="left">Ресурс</TableCell>
              <TableCell align="left">Контакт</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flatList.map((row) =>
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.publicId}
                </TableCell>
                <TableCell width="100px">
                  <Box sx={{ maxWidth: '50px', maxHeight: '50px', borderRadius: 1, overflow: 'hidden', border: '1px solid transparent', borderColor: 'grey.400', backgroundColor: 'grey.200' }}>
                    <img src={row?.images ? row?.images[0] : row?.imageUrl} width="50px" height="50px" alt="Flat"/>
                  </Box>
                </TableCell>
                <TableCell align="left">{row.ownerName}</TableCell>
                <TableCell align="left">{getCityText(row.city)}</TableCell>
                <TableCell align="left">{getDistrictText(row.district)}</TableCell>
                <TableCell align="left">{getStreetText(row.address)}</TableCell>
                {/*<TableCell align="left">{row.residence}</TableCell>*/}
                <TableCell align="left">{row.createdBy}</TableCell>
                <TableCell align="left">{row.createDate && getFormattedDate3(row.createDate)}</TableCell>
                <TableCell align="left">{row.crmSource}</TableCell>
                <TableCell align="left">
                  {(!row.owner?.number && row.owner?.link) &&
                    <Button onClick={() => handleAddOwnerClick(row.owner.link)} variant="contained" startIcon={<Add/>}>
                      контакт
                    </Button>}
                </TableCell>
                <TableCell align="right" width="120px">
                  <IconButton aria-label="edit" onClick={() => handleEditFlat(row._id)}>
                    <EditIcon/>
                  </IconButton>
                  <IconButton aria-label="edit" onClick={() => openRemoveDialog(row)}>
                    <PlaylistRemove/>
                  </IconButton>
                  {/*<IconButton aria-label="to-flats" onClick={() => setFlatToShow(row)}>*/}
                  {/*  <VisibilityOutlined/>*/}
                  {/*</IconButton>*/}
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!flatToRemove}
              onClose={() => setFlatToRemove(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Додати це оголошення у архів?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Після підтвердження це оголошення потрапить у архіви
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFlatToRemove(null)} color="primary">
            Відмова
          </Button>
          <Button onClick={handleRemoveFlatToArchive} color="primary" autoFocus>
            Погодити
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  )
}

export default FlatList
